export const GET_MAPS_BY_SHOW =  "GET_MAPS_BY_SHOW"
export const GET_ALL_SHOWS =  "GET_ALL_SHOWS"
export const GET_BOOTHS_BY_SHOW =  "GET_BOOTHS_BY_SHOW"
export const GET_BOOTHS_BY_MAP =  "GET_BOOTHS_BY_MAP"
export const GET_EXHIBITORS =  "GET_EXHIBITORS"
export const GET_SELECTED_BOOTH = "GET_SELECTED_BOOTH"
export const SET_SELECTED_EXHIBITORS = "SET_SELECTED_EXHIBITORS"
export const GET_CURRENT_SHOW = "GET_CURRENT_SHOW" 
export const GET_SHOW_NAME = "GET_SHOW_NAME" 
export const SET_SELECTED_MAP = "SET_SELECTED_MAP"
export const SET_SELECTED_BOOTH = "SET_SELECTED_BOOTH"
// export const SIGN_OUT = "SIGN_OUT"
export const GET_CURRENT_USER = "GET_CURRENT_USER"
export const GET_BOOKMARKED_ITEMS = "GET_BOOKMARKED_ITEMS"
export const GET_SESSIONS_UPTO_THIS_PAGE = "GET_SESSIONS_UPTO_THIS_PAGE"
export const GET_FILTERED_SESSIONS = "GET_FILTERED_SESSIONS"
export const GET_SESSIONS_BY_DATE = "GET_SESSIONS_BY_DATE"  
export const GET_SPEAKER_NAMES_BY_DATE = "GET_SPEAKER_NAMES_BY_DATE"
export const SET_FILTERING = "SET_FILTERING"
export const GET_ACCOUNT_INVOICE = "GET_ACCOUNT_INVOICE"
export const GET_ACCOUNT_INVOICE_PAYMENTS = "GET_ACCOUNT_INVOICE_PAYMENTS"
export const GET_ALL_PAST_SHOWS = "GET_ALL_PAST_SHOWS"
export const SET_404 = "SET_404"
export const SET_500 = "SET_500"
export const FETCH_AUTOCOMPLETE_VALUES_REQUEST = "FETCH_AUTOCOMPLETE_VALUES_REQUEST";
export const FETCH_AUTOCOMPLETE_VALUES_SUCCESS = "FETCH_AUTOCOMPLETE_VALUES_SUCCESS";
export const FETCH_AUTOCOMPLETE_VALUES_FAILURE = "FETCH_AUTOCOMPLETE_VALUES_FAILURE";
export const FETCH_EXHIBITOR_SEARCH_REQUEST = "FETCH_EXHIBITOR_SEARCH_REQUEST";
export const FETCH_EXHIBITOR_SEARCH_SUCCESS = "FETCH_EXHIBITOR_SEARCH_SUCCESS";
export const FETCH_EXHIBITOR_SEARCH_FAILURE = "FETCH_EXHIBITOR_SEARCH_FAILURE";
export const SET_EXHIBITOR_SEARCH_TERM = "SET_EXHIBITOR_SEARCH_TERM";
export const SET_SAVE_EXHIBITOR_SEARCH_RESULTS = "SET_SAVE_EXHIBITOR_SEARCH_RESULTS";
export const FETCH_EXHIBITORS_REQUEST = "FETCH_EXHIBITORS_REQUEST";
export const FETCH_EXHIBITORS_SUCCESS = "FETCH_EXHIBITORS_SUCCESS";
export const FETCH_EXHIBITORS_FAILURE = "FETCH_EXHIBITORS_FAILURE";
export const FETCH_SELECTED_THEME = "FETCH_SELECTED_THEME";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const FETCH_ACCOUNT_CONTACTS_REQUEST = "FETCH_ACCOUNT_CONTACTS_REQUEST";
export const FETCH_ACCOUNT_CONTACTS = "GET_ACCOUNT_CONTACTS";
export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_PRODUCTS_FAILURE = "FETCH_PRODUCTS_FAILURE"; 
export const FETCH_BOOTHS_REQUEST = "FETCH_BOOTHS_REQUEST";
export const FETCH_BOOTHS_SUCCESS = "FETCH_BOOTHS_SUCCESS";
export const FETCH_BOOTHS_FAILURE = "FETCH_BOOTHS_FAILURE";
export const FETCH_PRODUCTS_OF_ACCOUNT = "FETCH_PRODUCTS_OF_ACCOUNT";
export const UPDATE_CONTACT_IMAGE = 'UPDATE_CONTACT_IMAGE';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';