import {
  GET_FILTERED_SESSIONS,
  GET_SESSIONS_UPTO_THIS_PAGE,
  GET_SPEAKER_NAMES_BY_DATE,
  SET_FILTERING,
} from "store/actions/actionTypes";

// Function to group and merge sessions
const insertSessions = (responseSessions, state, isFiltering) => {

  // Group response sessions by sessionDate
  const groupedSessions = Object.values(
    responseSessions.reduce((acc, session) => {
      const { sessionDate } = session;
      if (!acc[sessionDate]) {
        acc[sessionDate] = {
          sessionDate,
          sessionsForDate: [],
        };
      }
      acc[sessionDate].sessionsForDate.push(session);
      return acc;
    }, {})
  );

  let sessionsList = [];

  if(!isFiltering){
    // Merge grouped sessions with existing state sessions
    sessionsList = [...(state?.sessions || [])].reduce(
      (acc, current) => {
        const matchingGroup = groupedSessions.find(
          (group) => group.sessionDate === current.sessionDate
        );
  
        if (matchingGroup) {
          // Merge sessions for the matching sessionDate
          acc.push({
            sessionDate: current.sessionDate,
            sessionsForDate: [
              ...current.sessionsForDate,
              ...matchingGroup.sessionsForDate.filter(
                (session) =>
                  !current.sessionsForDate.some(
                    (currentSession) => currentSession._id === session._id
                  )
              ),
            ],
          });
  
          // Remove the matching group to avoid duplicates
          groupedSessions.splice(groupedSessions.indexOf(matchingGroup), 1);
        } else {
          // Retain current sessions if no match is found
          acc.push(current);
        }
  
        return acc;
      },
      []
    );
  }

  
  // Add remaining new groups and sort by sessionDate
  sessionsList.push(...groupedSessions);
  sessionsList.sort((a, b) => a.sessionDate.localeCompare(b.sessionDate)); // sort the sessions by sessionDate

  return sessionsList;
};

// Reducer for handling sessions state
const sessionsReducer = (state = {
  // inital state of sessions State.
  speakerNamesByDate: null,
  sessions: null,
  totalSessions: 0, // Explicitly defined
  filteredSessions: null,
  selectValues: null,
  isFiltering: false,

}, action) => {
  
  switch (action.type) {
    case GET_FILTERED_SESSIONS: {
      // making crud operaton on filteredSessions of sessions state of redux
      if (action.payload) {
        const responseSessions = action.payload.filteredSessions;

        let sessionsList;

        if(action.payload.needGrouping){
          // if data need to group by sessionDate then do this
          sessionsList = insertSessions(responseSessions, state, true);
        }
        else{
          sessionsList = responseSessions;
        }

        if(action.payload.operation === 'INSERT'){
          // if operation is insert then only add data to the existing data.

          if(sessionsList && state.filteredSessions) {
            sessionsList = [...sessionsList, ...(state.filteredSessions)].sort((a, b) => a.sessionDate.localeCompare(b.sessionDate));
          }
        }
        else if(action.payload.operation === 'DELETE'){
          // if operation is delete then store null data
          sessionsList = null;
        }

        // store the filteredSessions Data in the  filteredSessions state of sessions state of redux.
        return { ...state, filteredSessions: sessionsList };
      }
      return state;
    }

    case GET_SESSIONS_UPTO_THIS_PAGE: {
      if (action.payload) {
        // getting , inserting and updating state.sessions.sessions state of redux.
        const responseSessions = action.payload.sessions;
        const sessionsList = insertSessions(responseSessions, state, false); // getting the sessionsList and updated the redux state of state.sessions

        return {
          ...state,
          sessions: sessionsList,
          totalSessions: action.payload.totalSessions || state.totalSessions,
        };
      }
      return state;
    }

    case GET_SPEAKER_NAMES_BY_DATE: {
      if (action.payload) {
        return {
          ...state,
          speakerNamesByDate: action.payload.speakerNamesByDate,
        };
      }
      return state;
    }

    // set the filtered select values
    case SET_FILTERING: {
      if(action.payload){
        return {
          ...state, selectValues: action.payload.selectValues, isFiltering: action.payload.isFiltering,
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default sessionsReducer;
