import getAxios from "common/axios";
import {
  getShowAbbreviation
} from "common";
import {
  fetchFromCache
} from "components/utils";

import {
  GET_MAPS_BY_SHOW,
  GET_EXHIBITORS,
  GET_SELECTED_BOOTH,
  GET_CURRENT_SHOW,
  GET_CURRENT_USER,
  GET_BOOKMARKED_ITEMS,
  GET_BOOTHS_BY_MAP,
  GET_SESSIONS_UPTO_THIS_PAGE,
  GET_FILTERED_SESSIONS,
  GET_SPEAKER_NAMES_BY_DATE,
  GET_ALL_SHOWS,
  GET_ACCOUNT_INVOICE,
  GET_ACCOUNT_INVOICE_PAYMENTS,
  GET_ALL_PAST_SHOWS,
  SET_SELECTED_MAP,
  SET_SELECTED_BOOTH,
  SET_500,
  SET_404,
  FETCH_AUTOCOMPLETE_VALUES_REQUEST,
  FETCH_AUTOCOMPLETE_VALUES_SUCCESS,
  FETCH_AUTOCOMPLETE_VALUES_FAILURE,
  FETCH_EXHIBITOR_SEARCH_FAILURE,
  FETCH_EXHIBITOR_SEARCH_REQUEST,
  FETCH_EXHIBITOR_SEARCH_SUCCESS,
  SET_EXHIBITOR_SEARCH_TERM,
  SET_SAVE_EXHIBITOR_SEARCH_RESULTS,
  FETCH_EXHIBITORS_REQUEST,
  FETCH_EXHIBITORS_SUCCESS,
  FETCH_EXHIBITORS_FAILURE,
  SET_SELECTED_EXHIBITORS,
  FETCH_ACCOUNT_CONTACTS,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,  
  FETCH_BOOTHS_REQUEST,
  FETCH_BOOTHS_SUCCESS,
  FETCH_BOOTHS_FAILURE,
  FETCH_PRODUCTS_OF_ACCOUNT,
  FETCH_ACCOUNT_CONTACTS_REQUEST,
  UPDATE_CONTACT_IMAGE,
  UPDATE_CONTACT
} from "store/actions/actionTypes";
const {
  REACT_APP_SERVER_BASE_URL
} = process.env;

const axios = getAxios();

export const fetchAllShows = (orgId) => async (dispatch, getState) => {
  if (orgId) {
    let res = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/exhibitorHome/activeShows`, {
      withCredentials: true
    });
    let shows = res.data.shows;
    const orgs = res.data.orgs || [];
    dispatch({
      type: GET_ALL_SHOWS,
      payload: shows,
      orgs
    })
  }
}

export const fetchAllPastShows = () => async (dispatch, getState) => {
  // if(orgId){
  let res = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/exhibitorHome/pastShows`, {
    withCredentials: true
  });
  let shows = res.data.shows;
  const orgs = res.data.orgs || [];
  dispatch({
    type: GET_ALL_PAST_SHOWS,
    payload: shows,
    orgs
  })
  // }
}

export const fetchMapsByShow = () => async (dispatch, getState) => {
  let maps = null;
  if (getState().show !== undefined && Object.keys(getState().show).length > 0) {
    maps = getState().show.maps;
  }
  dispatch({
    type: GET_MAPS_BY_SHOW,
    payload: maps
  })
};

export const fetchSelectedMapId = (mapId = null) => async (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_MAP,
    payload: {mapId}
  })
}
export const fetchSelectedBoothId = (boothId = null) => async (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_BOOTH,
    payload: {boothId}
  })
}

let source = null;
export const fetchBoothsByMap = (showId, mapId) => async (dispatch) => {
  let useCache = fetchFromCache();

  // save the new request for cancellation
  source && source.cancel()

  try {
    source = axios.CancelToken.source();
    const {data} = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/getbooths/${showId}/${mapId}`, {
      params: {
        useCache
      },
      cancelToken: source.token
    });
    dispatch({
      type: GET_BOOTHS_BY_MAP,
      payload: {
        booths: data.booths,
        map: data.map,
        show: data.show
      }
    })
  } catch (err) {
  }
};

export const fetchExhibitors = (showId = null) => async (dispatch, getState) => {
  let exhibitors = null
  try {
    if (showId) {
      let useCache = fetchFromCache();
      let res = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/getexhibitors/${showId}`, {
        params: {
          useCache
        }
      });
      exhibitors = res.data.exhibitors;
    }
    dispatch({
      type: GET_EXHIBITORS,
      payload: {showId, exhibitors}
    })
  } catch (err) {
    dispatch({
      type: GET_EXHIBITORS,
      payload: {showId, exhibitors: null}
    })
  }

};

export const fetchSelectedBoothInfo = (boothId) => async (dispatch, getState) => {
  if (boothId === null) {
    dispatch({
      type: GET_SELECTED_BOOTH,
      payload: {boothId, boothData: null, exhibitor: null}
    })
  }

  if (getState().show !== undefined && Object.keys(getState().show).length > 0) {
    let selectedMapId = getState().show.selectedMapId;
    let mapsData = getState().show.maps || null; //all maps
    let selectedMapData, boothsData, boothData = {_id: null}, exhibitor = [];
    const userId = getState()?.currentUser?._id || null;
    if (mapsData && Object.keys(mapsData).includes(selectedMapId)) {
      selectedMapData = mapsData && mapsData[selectedMapId];
      boothsData = selectedMapData && (selectedMapData.booths || null); //all booths of the selected map.
      boothData = (boothsData && boothsData[boothId]) || {_id: null};  //selected booth data.

      // let exhibitor = null;
      let accountId = boothData && boothData.accountId;
      if (accountId && !boothData.isReserved) {
        const res = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/exhibitors/booth/${boothId}`, {
          params: {
            userId
          }
        });
        exhibitor = res.data.exhibitor;
      }
    }

    dispatch({
      type: GET_SELECTED_BOOTH,
      payload: {boothData, exhibitor}
    })
  }
}

export const fetchSelectedExhibitors = (exhibitors = []) => async (dispatch, getState) => {
  if (exhibitors.length > 0) {
    const booth = getState().show?.selectedBooth?.booth;
    dispatch({
      type: SET_SELECTED_EXHIBITORS,
      payload: {exhibitors, booth}
    })
  }
}

export const fetchCurrentShow = (orgId = null, showId = null) => async (dispatch, getState) => {
  let show = null;
  let mapList = null;
  let categories = [];
  let payload = {show, mapList, categories};
  // const isExhibitor = false;

  // check for the exhibitor user
  // try {
  //   if (getState().currentUser !== undefined && Object.keys(getState().currentUser).length > 0) {
  //     isExhibitor = getState().currentUser.isExhibitor;
  //   }
  //   else {
  //     isExhibitor = false;
  //   }
  // } catch (err) {
  //   isExhibitor = false;
  // }

  // get show data from redux store.
  if (showId) {
    payload = getState().show;
  }

  /** Retrieve show data based on showAbbreviation */
  else {
    try {
      let useCache = fetchFromCache();
      var internalServerError = false;
      var pageNotFoundError = false;
      let showAbbreviation = getShowAbbreviation();
      if (showAbbreviation === "404") {
        dispatch({
          type: SET_404,
          payload: true
        });
        return;
      }
      /* Fetch both show as well as maps details of the show */
      const {data} = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/show/${showAbbreviation}`, {
        params: {
          useCache
        },
        withCredentials: true
      });
      // check for unpublished show response
      if (data.showUnpublished === true) {
        show = data;
      }
      else {
        show = data.show;
        if (show === null) {
          pageNotFoundError = true;
        }
        mapList = data.mapList;
        categories = data.categories || [];
      }
      payload = {show, mapList, categories};
    } catch (err) {
      internalServerError = true;
    }
  }

  dispatch({
    type: GET_CURRENT_SHOW,
    payload
  });

  dispatch({
    type: SET_404,
    payload: pageNotFoundError
  })

  dispatch({
    type: SET_500,
    payload: internalServerError
  })
}


/* 
  Fetch the currenly logged in user 
  After logout: set the currentUser value to null (in NavMenu component)
*/
export const fetchCurrentUser = (user = undefined) => async (dispatch, getState) => {
  // if null is passed then set the user id to null in the redux store.
  if (user === null) {
    /* set the id to null to indicate not logged in */
    let data = {_id: null}
    dispatch({
      type: GET_CURRENT_USER,
      payload: data
    })
  }
  if (user && Object.keys(user).length > 0) {
    dispatch({
      type: GET_CURRENT_USER,
      payload: user
    })
  }
  /* retrieve the current user from state if exists */
  if (getState().currentUser !== undefined && Object.keys(getState().currentUser).length > 0) {
    user = getState().currentUser;
    dispatch({
      type: GET_CURRENT_USER,
      payload: user
    })
    return;
  }
  try {
    axios.get(`${REACT_APP_SERVER_BASE_URL}/auth/login/success`, {
      withCredentials: true,
      params: {
        showAbbreviation: getShowAbbreviation()
      }
    })
      .then(res => {
        if (res.status === 200) {
          const data = res.data.user;
          dispatch({
            type: GET_CURRENT_USER,
            payload: data
          })
        }
      })
      .catch(() => {
        /* set the id to null to indicate not logged in */
        let data = {_id: null}
        dispatch({
          type: GET_CURRENT_USER,
          payload: data
        })
      })
  } catch (err) {
    /** check err: err.response */
    /* set the id to null to indicate not logged in */
    let data = {_id: null}
    dispatch({
      type: GET_CURRENT_USER,
      payload: data
    })
  }
}

export const fetchBookmarkedItems = (id, bookmarkAdded) => (dispatch, getState) => {
  // let bookmarkedItems = [];
  /* retrieve the current user from state if exists */
  if (getState().currentUser !== undefined && Object.keys(getState().currentUser).length > 0) {
    let currentUser = getState().currentUser;
    // bookmarkedItems = getState().currentUser.bookmarkedItems;
    if (bookmarkAdded) {
      currentUser.bookmarkedItems.push(id);
      currentUser.bookmarkedItems = [...new Set(currentUser.bookmarkedItems)]
    }

    /* delete item's id from bookmarkedItems list */
    else {
      currentUser.bookmarkedItems = currentUser.bookmarkedItems.filter(val => val !== id)
    }
    dispatch({
      type: GET_BOOKMARKED_ITEMS,
      payload: currentUser
    })
    return;
  }
}
let sessionCache = new Map();

let currentAbortController = null; // AbortController for the current request

// Calling API to fetch the filtered session based on select Values and update the filteredSessions state of sessions state in redux.
export const fetchFilteredSessions = (orgId, showId, selectValues, setFilterLoading) => async dispatch => {
  try{
    const keyword = selectValues.sessionDescription;

    // generating the key for storing data
    const cacheKey = `${orgId}-${showId}-${JSON.stringify(selectValues)}`;

    // if cache hit means the key is present in the cache then return the data without calling the API
    if(sessionCache.has(cacheKey)){
      return dispatch({
        type: GET_FILTERED_SESSIONS,
        payload: { filteredSessions: sessionCache.get(cacheKey), needGrouping: true, operation: 'UPDATE'}
      });
    }

    // Abort the previous request if a new one is made
    if (currentAbortController) {
      currentAbortController.abort();
    }

    // Create a new AbortController for the current request
    currentAbortController = new AbortController();

    setFilterLoading(true);

    // Make the API request
    const {data} = await axios.get(
      `${REACT_APP_SERVER_BASE_URL}/floorplan/filterSession/${orgId}/${showId}`,
      {
        // filter out the sessions that has following parmeters  
        params: {
          sessionType: selectValues.sessionType === 'All' ? '' : selectValues.sessionType, // no need to filter out by sessionType if we want to include all the sessions
          sessionTrack: selectValues.sessionTrack === 'All' ? '' : selectValues.sessionTrack, // no need to filter out by sessionTrack if we want to include all the session with the session track
          speakers: selectValues.speakers.map(({label, value}) => {return value}).join(','),
          selectedSessionDate: selectValues.selectedSessionDate.map(({label, value}) => {return value}).join(','), // converting the array into string separated by ','.
          keyword
        },
        signal: currentAbortController.signal,
      }
    );

    // Cache the result
    sessionCache.set(cacheKey, data.sessions);

    // updating the filteredSessions state with the fetch data.
    dispatch({
      type: GET_FILTERED_SESSIONS,
      payload: {filteredSessions: data.sessions,  needGrouping: true, operation:'UPDATE'}
    });
    setFilterLoading(false);
  }
  catch (err){
    if (err.message === 'canceled') {
      // Handle request abort case
    } else {
      // Dispatch error state
      dispatch({
        type: SET_500,
        payload: true,
      });
    }
  }
};

let currentAbortController2 = null;

// fetch sessionsByDate so that filtering or sessions can be get for a following date.
export const fetchSessionsByDate = (orgId, showId, fetchSessionForDate = [], isFiltering, setFilterLoading) => async dispatch => {
  try{
    // Abort the previous request if a new one is made
    if (currentAbortController2) {
      currentAbortController2.abort();
    }

    // Create a new AbortController for the current request
    currentAbortController2 = new AbortController();

    if(setFilterLoading) setFilterLoading(true);

    const {data} = await axios.get(
      `${REACT_APP_SERVER_BASE_URL}/floorplan/sessionsByDate/${orgId}/${showId}`, 
      {
        params: {
          sessionDateList: fetchSessionForDate.join(',')
        },
        signal: currentAbortController2.signal,
      }
    );

    // data comprise of message and sessions properties
    // insert the date into sessions state.
    dispatch({
      type: GET_SESSIONS_UPTO_THIS_PAGE,
      payload: {sessions: data.sessions}
    });

    if(isFiltering){
      // if filtering is on then insert the data into filteredSessions state of sessions state of redux
      dispatch({
        type: GET_FILTERED_SESSIONS,
        payload: {filteredSessions: data.sessions,  needGrouping: true, operation:'INSERT'}
      });
    }
    
    if(setFilterLoading) setFilterLoading(false);
  }
  catch (err){
    if (err.message === 'canceled') {
      // Handle request abort case
    } else {
      // Dispatch error state
      dispatch({
        type: SET_500,
        payload: true,
      });
    }
  }
};

// fetch the speakr names by date 
export const fetchSpeakerNamesByDate = (orgId, showId) => async dispatch => {
  try{
    const {data} = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/speakerNamesByDate/${orgId}/${showId}`);

    // after fetching update the state.sessions redux state
    dispatch({
      type: GET_SPEAKER_NAMES_BY_DATE,
      payload: {speakerNamesByDate: data.speakerNamesByDate}
    })
  } catch (err) {
    dispatch({
      type: SET_500,
      payload: true
    })
  }
};
// retrieve session details.
export const fetchSessions = (orgId, showId, skip, pageSize) => async dispatch => {
  let sessions = null;
  let totalSessions = null;

  try {
    // let useCache = fetchFromCache();
    // if page is not null then fetch the sessions for the particular page.
    if(pageSize){
      // pageSize is supplied then fetch the pageSize number of sessions after skipping the skip number of sessions.
      const {data} = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/sessions/${orgId}/${showId}/${skip}/${pageSize}`);
      sessions = data.sessions;
      totalSessions= data.totalSessions;

      // dispatch the sessions and totalSessions count to the reducer  to update the redux state
      dispatch({
        type: GET_SESSIONS_UPTO_THIS_PAGE,
        payload: {sessions, totalSessions},
      });

      return sessions?.length || 0;
    }
  } catch (err) {
    dispatch({
      type: SET_500,
      payload: true
    })
  }
}

export const fetchAccountInvoice = (accountId, showId) => async (dispatch, getState) => {
  let invoiceData = null;
  try {
    const {
      data
    } = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/${showId}/invoice`, {
      withCredentials: true,
      params: {
        accountId
      }
    })
    invoiceData = data.invoice;
  }
  catch (err) {
    invoiceData = null;
  }
  finally {
    dispatch({
      type: GET_ACCOUNT_INVOICE,
      payload: {invoice: invoiceData}
    })
  }
}

export const fetchAccountInvoicePayments = ({accountId, showId, orgId}) => async (dispatch) => {
  let invoicePayments = null;
  try {
    const {
      data
    } = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/invoice/payments`, {
      params: {
        accountId,
        showId,
        orgId
      },
      withCredentials: true
    })
    invoicePayments = data.invoicePayments;
  } catch (err) {
    invoicePayments = null;
  }
  finally {
    dispatch({
      type: GET_ACCOUNT_INVOICE_PAYMENTS,
      payload: {invoicePayments}
    })
  }
}

export const fetchAutocompleteValues = () => async dispatch => {
  dispatch({type: FETCH_AUTOCOMPLETE_VALUES_REQUEST});
  return fetch(`${REACT_APP_SERVER_BASE_URL}/floorplan/hashtags`)
    .then(response => response.json())
    .then(json => {
      dispatch({
        type: FETCH_AUTOCOMPLETE_VALUES_SUCCESS,
        payload: json.hashtags
      });
    })
    .catch(error => {
      dispatch({
        type: FETCH_AUTOCOMPLETE_VALUES_FAILURE,
        payload: error
      });
    });
};

// sort the exhibitors based on score then sort by exhibitor name.
const sortExhibitorsList = (exhibitorsData) => {
  exhibitorsData = exhibitorsData.sort((a, b) => {
    return b["score"] - a["score"] || b["exhibitorName"] - a["exhibitorName"];
  })
  return exhibitorsData;
}

let exhSearchSource = null;
export const fetchExhibitorSearchResult = ({showId = null, searchIn = null, term = null, currentPage = 1, selectedFilter = 0}) => async (dispatch, getState) => {
  let prevResults = [];
  if (currentPage > 1) {
    prevResults = getState().exhibitorSearch?.results || []
  }

  dispatch({
    type: FETCH_EXHIBITOR_SEARCH_REQUEST,
    term,
    selectedFilter
  });

  // if no parameters are passed, set results to empty list.
  if (term === null) {
    dispatch({
      type: FETCH_EXHIBITOR_SEARCH_SUCCESS,
      payload: [],
      hasMore: true,
      term: "",
      selectedFilter
    })
  }

  else {
    // cancel prev request.
    exhSearchSource && exhSearchSource.cancel("cancelled");
    try {
      // save the new request for cancellation
      exhSearchSource = axios.CancelToken.source();

      let q = new URLSearchParams({
        showId,
        searchIn,
        term,
        page: currentPage,
        limit: 5
      });
      // taking the response from API
      const {data} = await axios.get(
        `${REACT_APP_SERVER_BASE_URL}/floorplan/search/exh`, {
        cancelToken: exhSearchSource.token,
        params: q
      }
      );
      const sortedExhibitorsData = sortExhibitorsList(data.exhibitors);
      const allRetrievedResults = prevResults.concat(sortedExhibitorsData);

      dispatch({
        type: FETCH_EXHIBITOR_SEARCH_SUCCESS,
        payload: allRetrievedResults,
        hasMore: sortedExhibitorsData?.length === 5,
        term,
        selectedFilter
      })

    } catch (err) {
      if (err.message === "cancelled") {
        dispatch({
          type: FETCH_EXHIBITOR_SEARCH_FAILURE,
          payload: err,
          isFetching: true
        })
      }
      else {
        dispatch({
          type: FETCH_EXHIBITOR_SEARCH_FAILURE,
          payload: err
        })
      }
    }
  }
};

export const fetchProducts = (showId, orgId) => async (dispatch, getState) => {
  dispatch({
    type: FETCH_PRODUCTS_REQUEST
  })
  try {
    const {data} = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/products/${showId}/${orgId}`, {
      withCredentials: true
    })
    dispatch({
      type: FETCH_PRODUCTS_SUCCESS,
      payload: data.products
    })
  } catch (err) {
    dispatch({
      type: FETCH_PRODUCTS_FAILURE,
      payload: err
    })
  }
}

export const fetchProductsOfAccount = (accountId, showId) => async dispatch => {
  dispatch({
    type: FETCH_PRODUCTS_REQUEST
  })

  try {
    const {data} = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/${showId}/${accountId}/products`, {
      withCredentials: true
    })
    dispatch({
      type: FETCH_PRODUCTS_OF_ACCOUNT,
      sponsorships: data.sponsorships,
      confAttendees: data.confAttendees
    })
  } catch (err) {
    dispatch({
      type: FETCH_PRODUCTS_FAILURE,
      payload: err
    })
  }
}

export const fetchBoothsForAccount = (accountId, showId) => async (dispatch) => {
  dispatch({
    type: FETCH_BOOTHS_REQUEST
  });

  try {
    const { data } = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/${showId}/${accountId}/booths`, {
      withCredentials: true,
    });
    dispatch({
      type: FETCH_BOOTHS_SUCCESS,
      payload: data.booths,
    });
  } catch (err) {
    dispatch({
      type: FETCH_BOOTHS_FAILURE,
      payload: err,
    });
  }
};

export const setSearchTerm = (term) => async dispatch => {
  dispatch({
    type: SET_EXHIBITOR_SEARCH_TERM,
    payload: term
  })
}
export const setSaveSearchResults = (shouldSave) => async dispatch => {
  dispatch({
    type: SET_SAVE_EXHIBITOR_SEARCH_RESULTS,
    payload: shouldSave
  })
}

// exhibitors page (exhibitor portal)
export const fetchExhibitorsOfAccount = (accountId = null) => async (dispatch, getState) => {
  dispatch({
    type: FETCH_EXHIBITORS_REQUEST,
  })

  try {
    if (!accountId) {
      throw new Error("invalid account id")
    }

    const {data} = await axios.get(
      `${REACT_APP_SERVER_BASE_URL}/floorplan/${accountId}/exhibitors`,
      {
        withCredentials: true
      }
    )
    if (!data.shows) {
      throw new Error("Server Error");
    }

    dispatch({
      type: FETCH_EXHIBITORS_SUCCESS,
      shows: data?.shows,
      exhibitors: data?.exhibitors
    })
  } catch (err) {
    dispatch({
      type: FETCH_EXHIBITORS_FAILURE,
      payload: err
    })
  }
}

// fetch account contacts
export const fetchAccountContacts = ({accountId = null, orgId = null, showId = null}) => async (dispatch, getState) => {
  dispatch({
    type: FETCH_ACCOUNT_CONTACTS_REQUEST,
  })
  try {
    if (!accountId) {
      throw new Error("invalid account id")
    }
    const {data} = await axios.get(
      `${REACT_APP_SERVER_BASE_URL}/floorplan/${accountId}/contacts`,
      {
        withCredentials: true,
        params: {
          orgId,
          showId
        }
      }
    )
    if (!data.contacts) {
      throw new Error("Server Error");
    }
    dispatch({
      type: FETCH_ACCOUNT_CONTACTS,
      contacts: data.contacts
    })
  } catch (err) {
    dispatch({
      type: FETCH_ACCOUNT_CONTACTS,
      error: err
    })
  }
}

export const updateContactImage = (contactId, imageUrl) => {
  return {
    type: UPDATE_CONTACT_IMAGE,
    payload: { contactId, imageUrl }
  };
};

export const updateContact = (contactId, updatedData) => {
  return {
    type: UPDATE_CONTACT,
    contactId,
    updatedData
  };
};
