import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "common/axios";
const axios = getAxios();

const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export const fetchAttendees = createAsyncThunk(
  "attendees/fetchAttendees",
  async ({ showId, page, limit, sortBy, selectedLetter }) => {
    const params = { sortBy, selectedLetter };
    // Only add pagination when no specific letter filter is selected
    if (!selectedLetter) {
      params.page = page;
      params.limit = limit;
    }

    try {
      const response = await axios.get(
        `${REACT_APP_SERVER_BASE_URL}/floorplan/attendees/${showId}`,
        {
          params,
          withCredentials: true
        }
      );
      return { attendees: response.data.attendees, totalAttendees: response.data.totalAttendees };
    } catch (error) {
      console.error("Error fetching attendees:", error);
      throw error;
    }
  }
);

const attendeesSlice = createSlice({
  name: "attendees",
  initialState: {
    attendees: {}, // Store attendees by page number
    status: "idle",
    error: null,
    page: 1,
    limit: 50,
    totalAttendees: 0,
    selectedLetter: null
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setSelectedLetter: (state, action) => {
      state.selectedLetter = action.payload; // Update selectedLetter
      state.page = 1; // Reset to page 1 when the letter filter changes
      state.attendees = {}; // Clear attendees data when switching letter filters
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAttendees.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAttendees.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (state.selectedLetter) {
          state.attendees = { 1: action.payload.attendees }; // Store the filtered results for page 1
        } else {
          state.attendees[state.page] = action.payload.attendees; // Paginated results
        }
        state.totalAttendees = action.payload.totalAttendees;
      })
      .addCase(fetchAttendees.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
    }
});

export const { setPage, setLimit, setSelectedLetter } = attendeesSlice.actions;

export default attendeesSlice.reducer;
